import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { ButtonStyleDark } from "../../Core/Buttons/Styles"
import TextButton from "../../Core/Buttons/TextButton"
import { InputContainerStyle } from "../Styles"
import axios from "axios"
import { getUserErrorMessage } from "../../../services/core/accountFunctions"
import gsap from "gsap"

const FormContainer = styled.div`
  ${fonts.garamondRegular};
  max-width: 423px;
  margin: auto;
  width: 100%;
`

const Title = styled.h1`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 32px;
  margin-top: .67em;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.15;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    letter-spacing: normal;
  }
`

const InputContainer = styled.div`
  ${InputContainerStyle}
`

const SubmitButton = styled.button`
  ${ButtonStyleDark};
  max-width: unset;
`

const Navigations = styled.div`
  margin-top: 40px;
  display: grid;
  grid-gap: 30px;
  justify-content: center;
  text-align: center;

  a {
    letter-spacing: 1px;
    span {
      padding-bottom: 6px;
    }
  }
`

const RegisterButtonContainer = styled.div`
  display: grid;
`

const Claimer = styled.div`
  ${fonts.sofiaPro};
  margin-top: 4px;
  font-size: 12px;
`

const ServerError = styled.p`
  font-size: 14px;
  letter-spacing: .25px;
  color: #c00;
`

const ResetForm = () => {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [resetUrl, setResetUrl] = useState("")
  const [submitText, setSubmitText] = useState("Reset Password")
  const [error, setError] = useState("")

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentUrl = new URL(window.location.href)
      const shopifyUrl = currentUrl.search.replace("?reset_url=", "")
      setResetUrl(shopifyUrl)
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    let customerResetUrl = ""

    if (resetUrl.includes("%2F")) {
      const resetUrlArray = resetUrl.split('%2F')

      customerResetUrl = `https:\/\/${resetUrlArray[2]}\/${resetUrlArray[3]}\/${resetUrlArray[4]}\/${resetUrlArray[5]}\/${resetUrlArray[6].split("&syclid=").shift()}`
    } else {
      // Support the old format in case anyone uses one
      const resetUrlArray = resetUrl.split('/')

      customerResetUrl = `https:\/\/${resetUrlArray[2]}\/${resetUrlArray[3]}\/${resetUrlArray[4]}\/${resetUrlArray[5]}\/${resetUrlArray[6]}`
    }

    setSubmitText("Processing...")

    const url = '/.netlify/functions/shopifyUpdate'

    const confirmedPassword = password === confirmPassword ? password : false

    if (confirmedPassword) {

      const data = {
        "mutationType": "resetPassword",
        "data": {
          "password": confirmedPassword,
          "resetUrl": customerResetUrl
        }
      }

      try {
        const response = await axios.post(url, data)
  
        if (response.data.response.customerResetByUrl.customerUserErrors.length === 0) {
          setSubmitText("Success...")
          gsap.delayedCall(1, () => { window.location.href=`${process.env.GATSBY_SITE_HOSTNAME}/account/` })
        } else {
          const errorMessage = getUserErrorMessage(response.data.response.customerResetByUrl.customerUserErrors[0].message)
          setError(errorMessage)
          setSubmitText("Reset Password")
        }

      } catch {
        setError("Server error")
        setSubmitText("Reset Password")
      }
    } else {
      setError("Error: Passwords must match")
      setSubmitText("Save")
    }
  }

  return (
    <FormContainer>
      <Title>Reset Password</Title>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          <ServerError>{error}</ServerError>
        </InputContainer>
        <InputContainer>
          <label>New Password</label>
          <input
            type="password"
            placeholder="New Password"
            name="customer[newPassword]"
            autoCapitalize="off"
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <label>Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm Password"
            name="customer[confirmPassword]"
            autoCapitalize="off"
            autoComplete="new-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </InputContainer>
        <SubmitButton type={"submit"}>{submitText}</SubmitButton>
      </form>
      <Navigations>
      <RegisterButtonContainer>
          <TextButton to="/account/register/">
            Register
          </TextButton>
          <Claimer>(For existing customers)</Claimer>
        </RegisterButtonContainer>
        <TextButton to="/account/">Login</TextButton>
      </Navigations>
    </FormContainer>
  )
}

export default ResetForm
